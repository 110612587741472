<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewBox"
    fill="none"
    v-html="path"
  ></svg>
</template>

<script lang="ts" setup>
const props = defineProps({
  width: {
    type: [String, Number],
    required: true,
  },
  height: {
    type: [String, Number],
    required: true,
  },
  path: {
    type: String,
    required: true,
  },
});

const viewBox = computed(() => {
  return `0 0 ${props.width} ${props.height}`;
});
</script>
